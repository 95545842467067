var _process, _process$env;
var __jsx = React.createElement;
import '../themes/flexboxgrid.css';
import '../themes/global.css';
import React from 'react';
import GlobalStyle from '../themes/GlobalStyles';
import Store from '../app/store';
import withLinkValidation from '../utils/withLinkValidation';
function MyApp(_ref) {
  var Component = _ref.Component,
    pageProps = _ref.pageProps;
  return __jsx(Store, null, __jsx(GlobalStyle, null), __jsx(Component, pageProps));
}
export default ((_process = process) === null || _process === void 0 ? void 0 : (_process$env = _process.env) === null || _process$env === void 0 ? void 0 : _process$env.NEXT_PUBLIC_ENABLE_PREVIEW) === 'true' ? withLinkValidation(MyApp) : MyApp;